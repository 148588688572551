import React, { createContext, useContext, useState } from 'react';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    //   const navigate = useNavigate(); Navigation

    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        // Check localStorage for authentication state
        const storedAuth = localStorage.getItem('isAuthenticated');
        return storedAuth === 'true';
    });

    const login = (token, user) => {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true'); // Store in localStorage
        localStorage.setItem("access_token", token)
        localStorage.setItem("profile", JSON.stringify(user));
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.clear();
        window.location.href = '/'
    };


    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
