import React, { createContext, useState, useContext } from 'react';

// Create Context
const SidebarContext = createContext();

// Custom hook to use the SidebarContext
export const useSidebar = () => {
  return useContext(SidebarContext);
};

// SidebarProvider to manage the state
export const SidebarProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <SidebarContext.Provider value={{ isExpanded, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
