import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL, // Base URL from environment variable
    headers: {
        'Content-Type': 'application/json',
    },
});

// Request interceptor to include the token in headers
api.interceptors.request.use(
    (config) => {
        // Always add the token to the headers
        const token = localStorage.getItem('access_token'); // Adjust this based on where you store the token
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor (optional)
api.interceptors.response.use(
    (response) => response,
    (error) => {
        // Handle errors globally if needed
        if (error.response && error.response.status === 401) {
            // Handle token expiration logic here (e.g., logout or refresh token)
            // localStorage.removeItem('isAuthenticated');
            // localStorage.removeItem('access_token');
            localStorage.clear()
            window.location.href = '/'
            // Optionally navigate to login or show a message
            // For example: window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default api;
