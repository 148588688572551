import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import HeaderBar from './components/HeaderBar';
import Sidebar from './components/SideBar';
import Dashboard from './pages/Dashboard.js';
import UnderMaintenance from './components/UnderMaintenance';
import Login from './pages/Auth/Login.js';
import ProtectedRoute from './ProtectedRoute.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Candidates from './pages/Candidates/CandidatesList.js';
// import Recruiters from './pages/Re /Recruiters.js';
import Recruiters from './pages/Recruiters/Recruiters.js';
import MyJobs from './pages/MyJobs.js';
import AddCandidates from './pages/Candidates/AddCandidates.js';
import CandidateDetails from './pages/Candidates/CandidateDetails.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AddRecruiter from './pages/Recruiters/AddRecruiter.js';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Customize your primary color
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f4f6f8' // Customize background color
    }
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // Customize typography
  },
});

const protectedRoutes = [
  { path: '/dashboard', element: <Dashboard /> },
  { path: '/maintenance', element: <UnderMaintenance /> },
  { path: '/candidates', element: <Candidates /> },
  { path: '/recruiter', element: <Recruiters /> },
  { path: '/my-jobs', element: <MyJobs /> },
  { path: '/AddCandidates', element: <AddCandidates /> },
  { path: '/candidateDetails/:id', element: <CandidateDetails /> },
  { path: '/AddRecruiter', element: <AddRecruiter /> }
];

const App = () => {
  const isAuthenticated = !!localStorage.getItem('isAuthenticated');

  return (
    // <ThemeProvider theme={theme}>
    //   <CssBaseline />
    //   <Router>
    //     <Routes>
    //       <Route path='/login' element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
    //       <Route path='/' element={isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />

    //       {protectedRoutes.map(({ path, element }) => (
    //         <Route key={path} path={path} element={<ProtectedRoute element={element} />} />
    //       ))}

    //       <Route path="*" element={<Navigate to="/login" />} />
    //     </Routes>
    //     <ToastContainer />
    //   </Router>
    // </ThemeProvider>

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UnderMaintenance />
    </ThemeProvider>
  );
};


export default App;
