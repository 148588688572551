import React, { useState, useEffect } from 'react';
// import './style.css';

const UnderMaintenance = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const countdown = () => {
      const targetDate = new Date('2024-10-14T23:59:59').getTime();
      const now = new Date().getTime();
      const difference = targetDate - now;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({
        days: days < 10 ? `0${days}` : days,
        hours: hours < 10 ? `0${hours}` : hours,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds,
      });
    };

    const interval = setInterval(countdown, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container d-flex justify-content-center">
      <div className="content">
        <p>Website Is Under Maintenance</p>
        <h1>
          We're <span>Launching</span> Soon
        </h1>
        <div className="launch-time">
          <div>
            <p id="days">{timeLeft.days}</p>
            <span>Days</span>
          </div>
          <div>
            <p id="hours">{timeLeft.hours}</p>
            <span>Hours</span>
          </div>
          <div>
            <p id="minutes">{timeLeft.minutes}</p>
            <span>Minutes</span>
          </div>
          <div>
            <p id="seconds">{timeLeft.seconds}</p>
            <span>Seconds</span>
          </div>
        </div>
        <button type="button">
          Learn More{' '}
          <img src="https://i.postimg.cc/QC1THsDM/triangle.png" alt="triangle" />
        </button>
      </div>
      <img
        src="https://i.postimg.cc/PfwZ6bDk/rocket.png"
        alt="rocket"
        className="rocket"
      />
    </div>
  );
};

export default UnderMaintenance;
